<template lang="pug">
  .c-layout-dashboard
    .c-layout-dashboard__navbar
      navbar
    .c-layout-dashboard__body
      router-view
</template>

<script>
// import { mapState } from 'vuex'

export default {
  components: {
    // component
    navbar: () => import('@/components/02-molecule/navbar/default')
  },
  props: {
    // propierty: { required: false, type: String },
  },

  data () {
    return {
      data: ''
    }
  },

  computed: {
    // ...mapState({
    // homeCampaign: state => state.homeCampaign
    // })
  },
  watch: {},
  created () {
  },
  mounted () {},
  updated () {},
  methods: {
  }
}
</script>

<style lang="sass" scoped>
</style>
